.title-threethings{
  font-family: 'Bungee Shade', cursive;
  font-weight: 900;
  font-size: 40px;
}

.simple-three{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 22px;
  font-weight: 500;
}

.three-title{
  font-family: 'Carter One', cursive;
  font-size: 35px;
}

.three-thing-card {
  min-width: 400px; /* Adjust this to your preferred minimum width */
  min-height: 400px; /* Adjust this to your preferred minimum height */
  border-radius: 30px;
  background: white;
  box-shadow: 15px 15px 30px #bebebe,
             -15px -15px 30px #ffffff;
  padding: 20px; /* Add padding */
}
