@import url('https://fonts.googleapis.com/css2?family=Carter+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');


.navbar {
  background-color: aqua;
}

.card {
  border: 0;
}

.body {
  background-image: url(https://wallpaperaccess.com/full/5206080.jpg);
}



/* -------Vote--------- */

.voteDownButton{
  background-color: transparent;
  color: gray;
  border: none;
  padding: 10px 20px; /* Adjust these values as needed */
  font-size: 1.2em; /* Adjust as needed */
  transform: scale(1.2)
}

.voteButton {
  background-color: transparent;
  color: gray;
  border: none;
  padding: 10px 20px; /* Adjust these values as needed */
  font-size: 1.2em; /* Adjust as needed */
  transform: scale(1.2)
}








/* NAV */

.aal-container{
  display: flex;
  justify-content: flex-end;
  color: white !important;
}
  
.right-container{
  color: rgba(255, 255, 255, 0.502) !important;
  font-size: clamp(1.0rem, 2vw, 1.1rem) !important;
}

.custom-navbar{
  background-color: black !important;
}

.custom-navbar .nav-link {
  font-size: 30px; /* Adjust the font size as desired */
} 

.brand-container {
  font-size: clamp(1.0rem, 2vw, 1.2rem) !important;
  color: white !important;
}

.brand-container img {
  height: 40px;
}

.AboutMe-container {
  font-size: 30px;
}

.diplayname-container{
  font-size: 30px;
}







.buy-btn {
  width: 300px;
  font-size: clamp(1.1rem, 3vw, 1.2rem);
  padding: 10px;
  cursor: pointer;
  transform: scale(1.0);
  background-color: black;
  color: white;
  transition: transform 0.23s ease-in-out;
}


/* Disable hover effects on mobile */
@media (max-width: 768px) {
  .buy-btn:hover
  {
      transform: scale(1.0);
      filter: none;
  }
}



.modal-text1{
  border-bottom: black solid 1px;
  padding: 5px;
  font-size: clamp(1rem, 3vw, 1.1rem);
}

.about-text{
  font-family: 'Nunito Sans', sans-serif;
  font-size: clamp(0.8rem, 3vw, 0.9rem);
}

footer {
  margin-top: 100px;
}