@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap');

.header-title {
  font-family: 'Bungee Shade', cursive;
  font-weight: 900;
  font-size: clamp(1.8rem, 5vw, 4rem);
}



.info-header{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 22px;
  font-weight: 700;
}


.hash-link-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 20px;
}

.button-effect-collection{
  background-image: linear-gradient(72deg, var(--color-highlight-neon), var(--color-highlight-base));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  border-color: black;
}

.button-effect-collection {
  display: block;
  transform: scale(1.0);
  transition: transform 0.23s ease-in-out;
}

.button-effect-collection:hover {
  transform: scale(1.13);
  filter: drop-shadow(0 0 20px rgba(0, 5, 9, 0.919));
  z-index: 999;
}

/* .shoes-animation{
  background-image: url("../.././public/images/ezgif-5-7215c1509f.gif");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 100vh;
} */

.starter-header {
  color: white;
}

.info-header { 
  color: white;
}