/* Fancy Google hover google-button made by: csozi | Website: www.csozi.hu*/

.google-button {
  padding: 10px;
  font-weight: bold;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 35px;
  align-items: center;
  border: solid black 2px;
  outline: none;
}

.svg-1 {
  height: 25px;
  margin-right: 10px;
}

.google-button .text {
  z-index: 10;
  font-size: 14px;
}

.google-button:hover .text {
  animation: text forwards 0.3s;
  /*color: white;*/
}

@keyframes text {
  from {
    color: black;
  }

  to {
    color: white;
  }
}

.svg-1 {
  z-index: 6;
}

.google-button:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 300px;
  animation: wave1 2.5s ease-in-out forwards;
}

.google-button:hover::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 300px;
  animation: wave2 2.5s ease-in-out forwards;
}

@keyframes wave1 {
  0% {
    z-index: 1;
    background: #EB4335;
    width: 0;
    height: 0;
    opacity: 1;
  }

  1% {
    z-index: 1;
    background: #EB4335;
    width: 0;
    height: 0;
    opacity: 1;
  }

  25% {
    z-index: 1;
    background: #EB4335;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  26% {
    z-index: 3;
    background: #34A853;
    width: 0;
    height: 0;
    opacity: 1;
  }

  50% {
    z-index: 3;
    background: #34A853;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  70% {
    z-index: 3;
    background: #34A853;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  100% {
    z-index: 3;
    background: #34A853;
    width: 800px;
    height: 800px;
    opacity: 1;
  }
}

@keyframes wave2 {
  0% {
    z-index: 2;
    background: #FBBC05;
    width: 0;
    height: 0;
    opacity: 1;
  }

  11% {
    z-index: 2;
    background: #FBBC05;
    width: 0;
    height: 0;
    opacity: 1;
  }

  35% {
    z-index: 2;
    background: #FBBC05;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  39% {
    z-index: 2;
    background: #FBBC05;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  40% {
    z-index: 4;
    background: #4285F4;
    width: 0;
    height: 0;
    opacity: 1;
  }

  64% {
    z-index: 4;
    background: #4285F4;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  100% {
    z-index: 4;
    background: #4285F4;
    width: 800px;
    height: 800px;
    opacity: 1;
  }
}

.google-button:hover .red {
  animation: disappear 0.1s forwards;
  animation-delay: 0.1s;
}

.google-button:hover .yellow {
  animation: disappear 0.1s forwards;
  animation-delay: 0.3s;
}

.google-button:hover .green {
  animation: disappear 0.1s forwards;
  animation-delay: 0.7s;
}

.google-button:hover .blue {
  animation: disappear 0.1s forwards;
  animation-delay: 1.1s;
}

@keyframes disappear {
  from {
    filter: brightness(1);
  }

  to {
    filter: brightness(100);
  }
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  margin: 20px auto;
  transform: translate(-50%, -55%);
  background: white;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
}

.login-box p:first-child {
  margin: 0 0 30px;
  padding: 0;
  color: black;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: black;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: black;
  pointer-events: none;
  transition: .5s;
}


.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: black;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 3px
}




