@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.card-title{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.singledKick-Card{
  cursor: pointer;
}

.modal-body1{
  background-color: #ffffff;
    /* width: 200%; */
}

.modal-header {
  border-bottom: none !important;
}

.modal-title {
  margin-bottom: 0;
  /* margin: 0 28%; */
  text-align: center !important;
}

.modal-header{
justify-content: center !important;
border-bottom: 1px solid black !important;
margin-top: auto;
}

.modal-content {
  background-color: rgb(255, 255, 255) !important;
}





.sneakers-picture {
  aspect-ratio: 4/3;
  width: 380px;
  object-fit: cover;
}

.modal-open {
  display: flex;
}

.youtube-icon {
  font-size: 24px !important;
  color: red !important;
  text-decoration: none !important;
}

.whole-form {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px;
  margin: 20px auto;
  max-width: 500px;
}

.main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* .show-container {
  
} */

.sneakers-picture-modal {
  /* width: 100%; */
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.Delete-button {
  justify-content: center;
  display: flex;
  margin-bottom: 5px;
  padding-top: 5px;
}

.del-edit-btn{
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.Kick-Modal {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: brightness(100px);  
  -webkit-backdrop-filter: brightness(100px);  
  backdrop-filter: blur(7px);  
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.5)
}


* {
  box-sizing: border-box;
}


.button-effect .sneakers-picture{
  display: block;
  transform: scale(1.0);
  transition: transform 0.73s ease-in-out;
  z-index: 1;
}

.button-effect:hover .sneakers-picture {
  transform: scale(1.10);
  /* filter: drop-shadow(0 0 10px rgba(0, 5, 9, 0.319)); */
}

/* Disable hover effects on mobile */
@media (max-width: 768px) {
  .button-effect:hover
  {
      transform: scale(1.0);
      filter: none;
  }
}

.footer-text{
  font-size: 20px;
}