@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap');


.type-file {
  transition: all 0.3s ease-in-out;  /* This makes the change happen gradually over 0.3 seconds */
}

.type-file:hover {
  transform: scale(1.05);  /* Makes the element scale up to 110% of its original size */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); /* Adds a shadow */
  cursor: pointer;
}

.title-add-form{
  font-family: 'PT Sans Caption', sans-serif;
}


.whole-form {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px;
  margin: 20px auto;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 40px;
  
}

.whole-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.whole-form form {
  display: flex;
  flex-direction: column;
}

.whole-form label {
  font-weight: bold;
  margin-bottom: 5px;
}

.whole-form input,
.whole-form select {
  border-radius: 5px;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.whole-form select {
  margin-bottom: 20px;
}

/* .kickit-button {
  align-self: center;
  width: 150px;
  margin-top: 20px;
} */

.kickit-button {
  border: none;
  position: relative;
  width: 200px;
  height: 73px;
  padding: 0;
  z-index: 2;
  -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='868' width='2500' viewBox='0 0 726 252.17'%3E%3Cpath d='M483.92 0S481.38 24.71 466 40.11c-11.74 11.74-24.09 12.66-40.26 15.07-9.42 1.41-29.7 3.77-34.81-.79-2.37-2.11-3-21-3.22-27.62-.21-6.92-1.36-16.52-2.82-18-.75 3.06-2.49 11.53-3.09 13.61S378.49 34.3 378 36a85.13 85.13 0 0 0-30.09 0c-.46-1.67-3.17-11.48-3.77-13.56s-2.34-10.55-3.09-13.61c-1.45 1.45-2.61 11.05-2.82 18-.21 6.67-.84 25.51-3.22 27.62-5.11 4.56-25.38 2.2-34.8.79-16.16-2.47-28.51-3.39-40.21-15.13C244.57 24.71 242 0 242 0H0s69.52 22.74 97.52 68.59c16.56 27.11 14.14 58.49 9.92 74.73C170 140 221.46 140 273 158.57c69.23 24.93 83.2 76.19 90 93.6 6.77-17.41 20.75-68.67 90-93.6 51.54-18.56 103-18.59 165.56-15.25-4.21-16.24-6.63-47.62 9.93-74.73C656.43 22.74 726 0 726 0z'/%3E%3C/svg%3E") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='868' width='2500' viewBox='0 0 726 252.17'%3E%3Cpath d='M483.92 0S481.38 24.71 466 40.11c-11.74 11.74-24.09 12.66-40.26 15.07-9.42 1.41-29.7 3.77-34.81-.79-2.37-2.11-3-21-3.22-27.62-.21-6.92-1.36-16.52-2.82-18-.75 3.06-2.49 11.53-3.09 13.61S378.49 34.3 378 36a85.13 85.13 0 0 0-30.09 0c-.46-1.67-3.17-11.48-3.77-13.56s-2.34-10.55-3.09-13.61c-1.45 1.45-2.61 11.05-2.82 18-.21 6.67-.84 25.51-3.22 27.62-5.11 4.56-25.38 2.2-34.8.79-16.16-2.47-28.51-3.39-40.21-15.13C244.57 24.71 242 0 242 0H0s69.52 22.74 97.52 68.59c16.56 27.11 14.14 58.49 9.92 74.73C170 140 221.46 140 273 158.57c69.23 24.93 83.2 76.19 90 93.6 6.77-17.41 20.75-68.67 90-93.6 51.54-18.56 103-18.59 165.56-15.25-4.21-16.24-6.63-47.62 9.93-74.73C656.43 22.74 726 0 726 0z'/%3E%3C/svg%3E") no-repeat 50% 50%;
  -webkit-mask-size: 100%;
  cursor: pointer;
  background-color: transparent;
  transform: translateY(8px)
 }
 
 .container{
  text-align: center;
 }

 .kickit-button:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 0 0 0 white;
  transition: all 2s ease;
 }
 
 .kickit-button:hover:after {
  box-shadow: 0px -13px 56px 12px #ffffffa6;
 }
 
 .kickit-button span {
  position: absolute;
  width: 100%;
  font-size: 25px;
  font-weight: 300;
  left: 50%;
  top: 39%;
  letter-spacing: 3px;
  text-align: center;
  transform: translate(-50%,-50%);
  color: black;
  transition: all 2s ease;
 }
 
 .kickit-button:hover span {
  color: white;
 }
 
 .kickit-button:before {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background-color: black;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s ease;
 }
 
 .kickit-button:hover:before {
  width: 100%;
 }
 
 .footer-text{
  font-size: 20px;
}