.modal-backdrop.show {
  backdrop-filter: blur(4px);
}
.edit-btn {
  width: 160px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
  background: white;
  border: 1px solid #C1C1C1;
  transform: scale(.9);
 }
 .edit-btn, .edit-btn span {
  transition: 200ms;
 }
 .edit-btn .text {
  transform: translateX(35px);
  color: blue;
  font-weight: 400;
   font-size: 0.9rem;
 }
 .edit-btn .icon {
  position: absolute;
  border-left: 1px solid #C1C1C1;
  transform: translateX(110px);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .edit-btn svg {
  /* width: 100px; */
  fill: #C01010;
 }
 .edit-btn:hover {
  background: white;
 }
 .edit-btn:hover .text {
  color: transparent;
 }
 .edit-btn:hover .icon {
  width: 150px;
  border-left: none;
  transform: translateX(0);
 }
 .edit-btn:focus {
  outline: none;
 }
 .edit-btn:active .icon svg {
  transform: scale(0.8);
 }
.editform-btn {
  position: relative;
  outline: 0;
  border: 1px solid transparent;
  background-color: #488AEC;
  color: #FFFFFF;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
}
.editform-btn span {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  transition: all .4s ease;
}
button svg {
  width: 1.25rem;
  height: 1.25rem;
}
.editform-btn span:nth-child(2),.editform-btn span:nth-child(3) {
  position: absolute;
  top: 40px;
  color: #fff;
}
.editform-btn span:nth-child(2) {
  background-color: #488AEC;
}
.editform-btn span:nth-child(3) {
  background-color: #488AEC;
}
.editform-btn:hover {
  box-shadow: 0 10px 15px -3px #488aec4f, 0 4px 6px -2px #488aec17;
}
.editform-btn:hover span:nth-child(2), .editform-btn:focus span:nth-child(3) {
  top: 0;
}
.editform-btn:focus {
  box-shadow: none;
}
.singup {
  color: #000;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: block;
  font-weight: bold;
  font-size: x-large;
  margin-top: 1.5em;
}
.whole-form-card {
  justify-content: center;
  align-items: center;
  /* min-height: 350px; */
  /* width: 300px; */
  flex-direction: column;
  /* gap: 10px !important; */
  border-radius: 15px !important;
  background-color: white;
  box-shadow: 16px 16px 32px rgba(0, 206, 209, 0.748),
        -16px -16px 32px rgba(0, 206, 209, 0.744);
  border-radius: 8px;
}
.modal{
  --bs-modal-header-border-color:transparent !important;
}
.inputBox,
.inputBox1 {
  position: relative;
  width: 450px;
}
.inputBox input,
.inputBox1 input {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  color: #000;
  font-size: 1em;
  background: transparent;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transition: 0.1s;
  border-bottom-left-radius: 8px;
}
.inputBox span,
.inputBox1 span {
  margin-top: 5px;
  position: absolute;
  left: 0;
  transform: translateY(-4px);
  margin-left: 10px;
  padding: 10px;
  pointer-events: none;
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 3px;
  border-radius: 8px;
}
.inputBox input:valid~span,
.inputBox input:focus~span {
  transform: translateX(113px) translateY(-15px);
  font-size: 0.8em;
  padding: 5px 10px;
  background: #000;
  letter-spacing: 0.2em;
  color: #fff;
  border: 2px;
}
.inputBox1 input:valid~span,
.inputBox1 input:focus~span {
  transform: translateX(156px) translateY(-15px);
  font-size: 0.8em;
  padding: 5px 10px;
  background: #000;
  letter-spacing: 0.2em;
  color: #fff;
  border: 2px;
}
.inputBox input:valid,
.inputBox input:focus,
.inputBox1 input:valid,
.inputBox1 input:focus {
  border: 2px solid #000;
  border-radius: 8px;
}
.optionBox select {
  border: 2px solid #000; /* Change #000 to the color you want */
  border-radius: 8px;
}