.trash-btn {
 width: 160px;
 height: 50px;
 cursor: pointer;
 display: flex;
 align-items: center;
 background: white;
 border-radius: 5px;
 box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
 background: white;
 border: 1px solid #c1c1c1;
 transform: scale(.9);  
}

.trash-btn, .trash-btn span {
 transition: 200ms;
}

.trash-btn .text {
 transform: translateX(35px);
 color: #c01010;
 font-weight: 400;
  font-size: 0.9rem;
 
}

.trash-btn .icon {
 position: absolute;
 border-left: 1px solid #c1c1c1;
 transform: translateX(110px);
 height: 40px;
 width: 40px;
 display: flex;
 align-items: center;
 justify-content: center;
}

.trash-btn svg {
 width: 10px;
 fill: #c01010;
}

.trash-btn:hover {
 background: white;
}

.trash-btn:hover .text {
 color: transparent;
}

.trash-btn:hover .icon {
 width: 150px;
 border-left: none;
 transform: translateX(0);
}

.trash-btn:focus {
 outline: none;
}

.trash-btn:active .icon svg {
 transform: scale(0.8);
}